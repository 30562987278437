<template>
  <div class="modal fade" id="delete-user" tabindex="-1" aria-labelledby="delete-user-label" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h2 class="modal-title" id="delete-user-label">Supprimer {{ props.user.firstname }}</h2>
          <form autocomplete="off" method="post" action="" @submit.prevent="confirm">
            <div class="form-item">
              <p>Veuillez taper la phrase "supprimer {{ props.user.firstname.toLowerCase() }}"</p>
              <input type="text" v-model="phrase" />
            </div>

            <div class="form-item" v-if="error">
              <div class="alert alert-danger" role="alert">
                {{ error }}
              </div>
            </div>

            <div class="form-item text-center">
              <button type="submit" class="btn btn-primary ladda-button" data-style="expand-right">Valider</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue';
import axios from 'axios'
import * as Ladda from 'ladda';
const emit = defineEmits(['userDeleted']);
const props = defineProps(['user']);
let phrase = ref("");
let error = ref("");
let modal;

onMounted(() => {
  const bootstrap = require('bootstrap');
  const modalElement = document.getElementById('delete-user');
  modal = new bootstrap.Modal(modalElement);
})

function confirm() {
  error.value = '';
  if(phrase.value != "supprimer " + props.user.firstname.toLowerCase()) {
    error.value = 'Pas la bonne phrase.';
  }
  else {
    var l = Ladda.create(document.querySelector('#delete-user .ladda-button'));
    l.start();
    let data = new FormData();
    data.append('userid', props.user.id);
    axios.post('delete-user', data)
    .then(function(result) {
      l.stop();
      if(result.data.error) {
        error.value = result.data.error;
      }
      else if(result.data.success) {
        emit('userDeleted')
        modal.hide();
      }
    })
  }
}
</script>