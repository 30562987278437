<template>
  <div class="outer-wrapper">
    <div class="logo text-center">
        <router-link :to="{ path: '/' }">
          <LogoLicorn style="width: 50px;" class="d-none d-xl-block" />
          <LogoLicornHorizontal style="width: 131px;" class="d-xl-none" />
        </router-link>
    </div>
    <div class="main">
        <div class="menu" v-if="userStore.isLoggedIn">
          <router-link data-bs-toggle="tooltip" title="Congés de l'équipe" :to="{ name: 'team_vacations' }"><PictoHome /></router-link>
          <router-link data-bs-toggle="tooltip" title="Mes congés" :to="{ name: 'user_vacations', params: { 'username': userStore.getCurrentUsername, 'type': 'rtt' } }" v-if="! userStore.isAdmin"><PictoProfil /></router-link>
          <router-link data-bs-toggle="tooltip" title="Mon profil" :to="{ name: 'profile', params: { 'username': userStore.getCurrentUsername } }"><PictoParametres /></router-link>
          <LogoutButton />
        </div>
        <div class="well">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
    </div>
  </div>
</template>

<script setup>
  import LogoutButton from './views/components/LogoutButton.vue'
  import { useUserStore } from "@/stores/UserStore";
  import LogoLicorn from './views/svg/LogoLicorn.vue';
  import LogoLicornHorizontal from './views/svg/LogoLicornHorizontal.vue';
  import PictoHome from './views/svg/PictoHome.vue';
  import PictoProfil from './views/svg/PictoProfil.vue';
  import PictoParametres from './views/svg/PictoParametres.vue';
  const userStore = useUserStore();
</script>

<style lang="scss" src="./assets/scss/app.scss"></style>
