<template>
  <button data-bs-toggle="modal" data-bs-target="#add-user" class="btn btn-primary" type="button"><i class="bi bi-plus-circle"></i> Ajouter un utilisateur</button>

  <div class="modal fade" id="add-user" tabindex="-1" aria-labelledby="add-user-label" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body size-big">
          <h2 class="modal-title" id="add-user-label">Ajouter un utilisateur</h2>
          <form autocomplete="off" method="post" action="" @submit.prevent="update">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-item">
                    <input type="text" v-model="firstname" placeholder="Prénom" />
                </div>
                <div class="form-item">
                    <input type="text" v-model="lastname" placeholder="Nom de famille" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-item">
                    <input type="email" v-model="email" placeholder="Email" />
                </div>
                <div class="form-item">
                  <input type="text" v-model="nickname" placeholder="Petit surnom" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-item">
                    <input type="password" v-model="password1" placeholder="Mot de passe" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-item">
                    <input type="password" v-model="password2" placeholder="Confirmation" />
                </div>
              </div>
            </div>

            <div class="form-item" v-if="error">
              <div class="alert alert-danger" role="alert">
                {{ error }}
              </div>
            </div>

            <div class="form-item text-center">
              <button type="submit" class="btn btn-primary ladda-button" data-style="expand-right">Valider</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue';
import axios from 'axios';
import * as Ladda from 'ladda';
const emit = defineEmits(['userAdded'])

let firstname = ref("");
let lastname = ref("");
let email = ref("");
let nickname = ref("");
let password1 = ref("");
let password2 = ref("");
let error = ref("");
let modal;

onMounted(() => {
  const bootstrap = require('bootstrap');
  const modalElement = document.getElementById('add-user');
  modal = new bootstrap.Modal(modalElement);
})

function update() {
  let data = new FormData();
  if(password1.value != password2.value) {
    error.value = 'Les mdp doivent correspondre. Aucun 2ème mojito ne sera autorisé.';
    return;
  }
  else {
    data.append('password', password1.value);
  }
  data.append('firstname', firstname.value);
  data.append('lastname', lastname.value);
  data.append('email', email.value);
  data.append('nickname', nickname.value);
  var l = Ladda.create(document.querySelector('#add-user .ladda-button'));
  l.start();
  axios.post('add-user', data)
  .then(function(result) {
    l.stop();
    if(result.data.error) {
      error.value = result.data.error;
    }
    else if(result.data.success) {
      emit('userAdded')
      modal.hide();
    }
  })
}
</script>