import { defineStore } from 'pinia'

export const useUserStore = defineStore('UserStore', {
  // state
  state: () => {
    return {
      email: '',
      roles: '',
      firstname: '',
      lastname: '',
      nickname: ''
    }
  },

  // actions
  actions: {
    setProfile(data) {
      this.email = data.email;
      this.roles = data.roles;
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.nickname = data.nickname;
    },

    logout() {
      this.setProfile({});
    }
  },

  getters: {
    isLoggedIn(state) {
      return Boolean(state.email);
    },
    getCurrentUsername(state) {
      const getUsername = this.getUsername;
      return getUsername(state.email);
    },
    getUsername() {
      return (email) => email.substring(0, email.indexOf("@"));
    },
    isAdmin(state) {
      if(state.roles) {
        return state.roles.includes('ROLE_ADMIN');
      }
      return false;
    }
  }
})