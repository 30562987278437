<template>
  <div class="component-wrapper">
    <header>
      <div class="page-title">
        <div class="intro">
          <h1>Soldes équipe</h1>
          <p>Qui est le champion des RTT ?</p>
        </div>
      </div>
      <div class="page-buttons">
        <RequestVacationButton v-if="!userStore.isAdmin" @vacation-added="loadUsers" />
        <AddUserButton v-else @user-added="loadUsers" />
        <DeleteUserModal :user="state.userToDelete" @user-deleted="loadUsers" />
      </div>
    </header>
    <div class="user-list">
      <div class="user-line" v-for="(user, index) in state.users" :key="user.email">
        <div class="number">{{ index + 1 }}</div>
        <div class="username">
          <router-link :to="{ name: 'user_vacations', params: { 'username': getUsername(user.email), 'type': 'rtt' } }" v-if="userStore.isAdmin || userStore.email == user.email">
            {{ user.firstname }} <em>{{ user.nickname }}</em> {{ user.lastname }}
          </router-link>
          <span v-else>{{ user.firstname }} <em>{{ user.nickname }}</em> {{ user.lastname }}</span>
        </div>
        <div class="total">{{ total(user.total) }} RTT disponibles</div>
        <div class="progressbar">
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="{ width: progressWidth(user.total), backgroundColor: progressBg(user.total) }" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <div class="btn-container">
          <router-link class="action" :to="{ name: 'profile', params: { 'username': getUsername(user.email) } }" v-if="userStore.isAdmin"><i class="bi bi-pencil"></i></router-link>
        </div>
        <button data-bs-toggle="modal" data-bs-target="#delete-user" class="action unstyled text-primary" type="button" v-if="userStore.isAdmin" :data-user-key="index"><i class="bi bi-trash"></i></button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import DeleteUserModal from '../components/DeleteUserModal.vue'
  import RequestVacationButton from '../components/RequestVacationButton.vue';
  import AddUserButton from '../components/AddUserButton.vue';
  import { reactive, onMounted } from 'vue';
  import { useUserStore } from "@/stores/UserStore";
  const userStore = useUserStore();
  const getUsername = userStore.getUsername;
  const state = reactive({
    users: [],
    userToDelete: { firstname: 'toto' }
  })
  loadUsers();

  onMounted(() => {
    const modalElement = document.getElementById('delete-user');
    modalElement.addEventListener('show.bs.modal', (event) => {
      let button = event.relatedTarget;
      let userKey = button.getAttribute('data-user-key');
      state.userToDelete = state.users[userKey];
    });
  })

  function loadUsers() {
    const axios = require('axios');
    axios.get('vacations/team')
    .then((result) => {
      if(result.data.users) {
        state.users = result.data.users;
      }
    })
  }

  function progressWidth(total) {
    return (total * 100 / 18) + '%';
  }

  function progressBg(total) {
    let percent = (total * 100 / 18) / 100;
    let hue = ((1 - percent) * 120).toString(10);
    return 'hsl('+ hue + ',75%,50%)';
  }

  function total(total) {
    return total || 0;
  }
</script>