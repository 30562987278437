<template>
  <div class="component-wrapper">
    <div class="intro">
      <h1>Connexion</h1>
      <p>Nous ne savons pas qui vous êtes, merci de montrer la peau de l'ours blanche.</p>
    </div>
    <form method="post" action="" @submit.prevent="login" style="max-width: 400px">
        <div class="form-item">
            <input type="email" v-model="email" placeholder="Votre identifiant" />
        </div>
        <div class="form-item">
            <input type="password" v-model="password" placeholder="Votre mot de passe" />
        </div>
        <div class="form-submit form-item">
            <button type="submit" class="btn btn-primary ladda-button" data-style="expand-right">Connexion <i class="bi bi-arrow-right"></i></button>
        </div>

        <div class="form-item">
          <router-link :to="{ name: 'reset_password_query' }" class="link-default">Vous avez oublié votre mot de passe ?</router-link>
        </div>

        <div class="error">{{ error }}</div>
    </form>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/UserStore";
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import * as Ladda from 'ladda';

const userStore = useUserStore();
const email = ref("");
const error = ref("");
const password = ref("");
const router = useRouter();

function login() {
  var l = Ladda.create(document.querySelector('.ladda-button'));
  l.start();

  const axios = require('axios');
  axios.post('login', {
    username: email.value,
    password: password.value
  })
  .then((result) => {
    l.stop();
    userStore.setProfile(result.data);
    router.push({ path: '/' });
  })
  .catch(function(result) {
    l.stop();
    if(result.response.data.error) {
      error.value = result.response.data.error;
    }
  })
}
</script>