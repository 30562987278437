<template>
  <button data-bs-toggle="modal" data-bs-target="#request-vacation" class="btn btn-primary" type="button">
    <PictoPlus /> Poser un rtt / cp
  </button>

  <div class="modal fade" id="request-vacation" tabindex="-1" aria-labelledby="request-vacation-label" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body size-big">
          <h1 class="modal-title" id="request-vacation-label">Poser un {{ type }}</h1>
          <form autocomplete="off" method="post" action="" @submit.prevent="request" v-show="!success">
            <div class="form-item">
              <label class="form-label d-block">Ma demande concerne un </label>
              <label class="radio-container">RTT
                <input type="radio" v-model="type" name="type" value="RTT">
                <span class="checkmark"></span>
              </label>
              <label class="radio-container">CP
                <input type="radio" v-model="type" name="type" value="CP">
                <span class="checkmark"></span>
              </label>
            </div>

            <div class="form-item d-lg-flex">
              <div class="mb-3 mb-lg-0">
                <label class="form-label d-block" for="dateStart">Du</label>
                <input type="text" ref="dateStartElement" id="dateStart" />
              </div>
              <div class="ms-lg-3 mb-3 mb-lg-0">
                <label class="form-label d-block" for="dateEnd">Au</label>
                <input type="text" ref="dateEndElement" id="dateEnd" />
              </div>
              <div class="ms-lg-3 mb-3 mb-lg-0">
                <label class="form-label d-block" for="duration">Durée</label>
                <select class="form-select" v-model="duration">
                  <option value="">Toute la journée</option>
                  <option value="am">Matin</option>
                  <option value="pm">Après-midi</option>
                </select>
              </div>
              <div class="ms-lg-3">
                <label class="form-label d-block">Action</label>
                <button type="button" class="btn btn-primary" @click="addLine">Ajouter</button>
              </div>
            </div>

            <div class="form-item">
              <ul>
                <li v-for="(line, index) in state.lines" :key="index">{{ formatLine(line) }} <button type="button" class="unstyled" @click="removeLine(index)"><i class="bi bi-x"></i></button></li>
              </ul>
            </div>

            <div class="form-item total-days">
              TOTAL : <span class="number">{{ total }}</span>  jours
            </div>

            <div class="form-item" v-if="error">
              <div class="alert alert-danger" role="alert">
                {{ error }}
              </div>
            </div>

            <div class="form-item text-center" v-if="total > 0">
              <button type="submit" class="btn btn-primary ladda-button" data-style="expand-right">Valider ma demande</button>
            </div>
          </form>
          <div v-show="success">
            <div class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <button type="button" class="btn btn-primary" @click="resetForm">Une autre ! Une autre !</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PictoPlus from '../svg/PictoPlus.vue';
import {isBankHoliday} from '../../services/BankHolidays';
import { onMounted, onUnmounted, ref, reactive, computed, defineEmits } from 'vue';
import axios from 'axios';
import * as Ladda from 'ladda';
const datepicker = require('js-datepicker')
let dateStartElement = ref(null);
let dateEndElement = ref(null);
let dateStart = ref("");
let dateEnd = ref("");
let type = ref("RTT");
let duration = ref("");
let error = ref("");
let success = ref("");
const state = reactive({ days: [], lines: [] })
let pickerStart, pickerEnd
const emit = defineEmits(['vacationAdded'])

onMounted(() => {
  let datepickerParams = {
    formatter: (input, date) => {
      const value = date.toLocaleDateString();
      input.value = value;
    },
    customDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    customOverlayMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
    noWeekends: true,
    id: 1,
    minDate: new Date(),
    startDay: 1, // monday
    onSelect: (instance, date) => {
      if(instance.el.id == 'dateStart') { dateStart.value = date; }
      else if(instance.el.id == 'dateEnd') { dateEnd.value = date; }
    }
  }
  pickerStart = datepicker(dateStartElement.value, datepickerParams);
  pickerEnd = datepicker(dateEndElement.value, datepickerParams);
})

onUnmounted(() => {
  pickerStart.remove();
  pickerEnd.remove();
})

const total = computed(() => {
  let total = 0;
  for(const index in state.days) {
    const item = state.days[index];
    if(!item.am && !item.pm) total += 1;
    if(item.am || item.pm) total += 0.5;
  }
  return total;
})

function request() {
  let data = new FormData();
  data.append('type', type.value);
  const days = [];
  for(const index in state.days) {
    const item = state.days[index];
    days.push({
      am: item.am,
      pm: item.pm,
      date: item.date.getTime() / 1000
    });
  }
  data.append('days', JSON.stringify(days));
  data.append('total', total.value);
  var l = Ladda.create(document.querySelector('#request-vacation .ladda-button'));
  l.start();
  axios.post('vacations/request', data)
  .then(function(result) {
    l.stop();
    if(result.data.error) {
      error.value = result.data.error;
    }
    else if(result.data.success) {
      success.value = result.data.success;
      emit('vacationAdded')
    }
  })
}

function addLine() {
  // reset error
  error.value = '';
  const range = pickerStart.getRange();

  // check if start date is present
  if( ! range.start) {
    error.value = 'Le Gwen Ha Du est requis. Heu le champ "Du" est requis je veux dire.';
    return;
  }

  // if state.days already contains one the the selected days => abort
  const end = range.end || range.start;
  let currentDay = new Date(range.start.getTime());
  let exists = false;
  do {
    exists = state.days.some(element => currentDay.toLocaleDateString() == element.date.toLocaleDateString());
    if(exists) break;
    currentDay.setDate( currentDay.getDate() + 1 );
  } while(currentDay <= end);

  if(exists) {
    error.value = 'Pas si vite ! Un des jours sélectionnés a déjà été pris ! On peut tromper 1 fois 1000 personnes mais pas prendre 1000 fois 1 RTT !';
    resetDates();
    return;
  }
  else {
    // add selected days into state.days
    currentDay = new Date(range.start.getTime());
    do {
      const weekends = [0, 6];
      if(! weekends.includes(currentDay.getDay()) && ! isBankHoliday(currentDay)) {
        let data = {
          date: new Date(currentDay.getTime()),
          am: false,
          pm: false
        }
        // if the 'date end' field is filled, we consider all days are full
        if( ! range.end || range.start.getTime() == range.end.getTime()) {
          if(duration.value == 'am') data.am = true;
          else if(duration.value == 'pm') data.pm = true;
        }
        state.days.push(data);
      }
      currentDay.setDate( currentDay.getDate() + 1 );
    } while(currentDay <= end)

    // push data into state.lines
    const data = {
      dateStart: dateStart.value
    }
    if(dateEnd.value) { data.dateEnd = dateEnd.value; }
    else if (duration.value) { data.duration = duration.value }
    state.lines.push(data);

    // reset form
    resetDates();
  }
}

function resetDates() {
  pickerStart.setDate();
  pickerEnd.setDate();
  dateStart.value = '';
  dateEnd.value = '';
  duration.value = '';
}

function resetForm() {
  resetDates();
  success.value = false;
  state.days = [];
  state.lines = [];
}

function formatLine(line) {
  let labelStart = 'Le';
  if(line.dateEnd) labelStart = 'Du' ;
  let result = labelStart + ' ' + line.dateStart.toLocaleDateString();
  if(line.dateEnd) {
    result += ' au ' + line.dateEnd.toLocaleDateString();
  } else if(line.duration) {
    result += ' ' + line.duration;
  }
  return result;
}

function removeLine(index) {
  const line = state.lines[index];
  state.lines.splice(index, 1);

  // remove associated days
  let currentDay = new Date(line.dateStart.getTime());
  let end = line.dateEnd || line.dateStart;
  do {
    state.days = state.days.filter(element => currentDay.toLocaleDateString() != element.date.toLocaleDateString());
    currentDay.setDate( currentDay.getDate() + 1 );
  } while(currentDay <= end);
}
</script>