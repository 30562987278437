<template>
  <div class="component-wrapper">
    <header>
      <div class="page-title"><h1>{{ state.user.firstname }}</h1></div>
      <div class="page-buttons">
        <UpdateVacationButton @vacation-added="getHistory" v-if="userStore.isAdmin" />
        <RequestVacationButton @vacation-added="getHistory" v-else />
      </div>
    </header>

    <ul class="vacation-type list-inline">
      <li class="list-inline-item"><router-link :to="{ name: 'user_vacations', params: { 'username': getUsername(state.user.email), 'type': 'rtt' } }">Historique des RTT</router-link></li>
      <li class="list-inline-item"><router-link :to="{ name: 'user_vacations', params: { 'username': getUsername(state.user.email), 'type': 'cp' } }">Historique des CP</router-link></li>
    </ul>

    <div class="d-xl-flex mt-5">
      <div class="vacation-total mt-2">
        <span class="number">{{ total }}</span>
        <span class="type">{{ route.params.type }}</span>
        <span class="dispo">disponibles</span>
      </div>
      <div class="vacation-scrollbar" v-if="state.vacations.length">
        <div class="vacation-table">
          <div class="table-header">
            <div class="table-cell heading-date">Date de l'actualisation</div>
            <div class="table-cell heading-action">Action</div>
            <div class="table-cell">Motif</div>
            <div class="table-cell">Approuvé</div>
            <div class="table-cell"><i class="bi bi-trash"></i></div>
          </div>
          <div :class="lineClass(vacation.total)" v-for="(vacation, index) in state.vacations" :key="vacation.id">
            <div class="table-cell">{{ formatDate(vacation.date) }}</div>
            <div class="table-cell">{{ formatOperation(vacation.total) }}</div>
            <div class="table-cell">{{ vacation.label }}</div>
            <div class="table-cell">
              <span v-if="vacation.approvalPending">Chargement&hellip;</span>
              <span class="badge bg-success" v-else-if="!userStore.isAdmin && vacation.approved">Approuvé</span>
              <button class="unstyled text-primary" type="button" v-else-if="userStore.isAdmin && vacation.approved" @click="toggleApproved(index)"><span>Approuvé</span></button>
              <span class="badge bg-danger" v-else-if="!userStore.isAdmin && !vacation.approved">En attente</span>
              <button class="unstyled text-primary" type="button" v-else-if="userStore.isAdmin && !vacation.approved" @click="toggleApproved(index)"><span>En attente</span></button>
            </div>
            <div class="table-cell"><button @click.prevent="deleteLine(index)" class="unstyled text-primary" type="button" v-if="userStore.isAdmin || !vacation.approved"><i class="bi bi-trash"></i></button></div>
          </div>
        </div>
      </div>
      <div v-else>
        Aucun {{ route.params.type.toUpperCase() }} pour le moment :(
      </div>
    </div>
  </div>
</template>

<script setup>
  import RequestVacationButton from '../components/RequestVacationButton.vue';
  import UpdateVacationButton from '../components/UpdateVacationButton.vue';
  import { reactive, watch, computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router'
  import { useUserStore } from "@/stores/UserStore";
  import axios from 'axios'
  const userStore = useUserStore();
  const getUsername = userStore.getUsername;
  const route = useRoute();
  const router = useRouter();
  const state = reactive({
    vacations: [],
    total: 0,
    user: { firstname: 'Utilisateur', email: 'test@example.com' }
  })
  watch(() => route.params.type, (type) => {
    if(type) {
      getHistory()
    }
  })
  getHistory()

  const total = computed(() => {
    return state.total || 0;
  })

  function getHistory() {
    if( ! userStore.isAdmin && getUsername(userStore.email) !== route.params.username) {
      router.push({ name: 'access_denied' });
    }
    else {
      const axios = require('axios');
      axios.get('vacations/' + route.params.username + '/' + route.params.type)
      .then((result) => {
        if(result && result.data.vacations && typeof result.data.total !== 'undefined' && result.data.user) {
          state.vacations = result.data.vacations;
          state.total = result.data.total;
          state.user = result.data.user;
        }
        else if(result && result.data.error) {
          console.log(result.data.error);
        }
      })
    }
  }

  function lineClass(total) {
    let className = 'table-line';
    if(total > 0) className += ' gain';
    else className += ' loss';
    return className;
  }

  function formatOperation(operation) {
    let result = route.params.type.toUpperCase() + ' ';
    if(operation > 0) {
      result += '+';
    }
    result += operation;
    return result;
  }

  function formatDate(string) {
    let date = new Date(string);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function deleteLine(vacationIndex) {
    const vacationItem = state.vacations[vacationIndex];
    let data = new FormData();
    data.append('vacationid', vacationItem.id);
    data.append('username', route.params.username);
    // this action is authorized if the current user is admin or if the vacation is not yet approved
    if(userStore.isAdmin || !vacationItem.approved) {
      if(confirm("Confirmer la suppression ?")) {
        axios.post('vacations/delete', data)
        .then(function(result) {
          if(result.data.error) {
            alert(result.data.error)
          }
          else if(result.data.success) {
            state.vacations = state.vacations.filter(item => vacationItem.id !== item.id);
            if(vacationItem.approved) {
              state.total -= vacationItem.total;
            }
          }
        })
      }
    }
  }

  function toggleApproved(vacationIndex) {

    const vacationItem = state.vacations[vacationIndex];
    vacationItem.approvalPending = true;
    let data = new FormData();
    data.append('vacationid', vacationItem.id);
    data.append('username', route.params.username);

    axios.post('vacations/toggleApproved', data)
    .then(function(result) {
      vacationItem.approvalPending = false;
      if(result.data.error) {
        alert(result.data.error)
      }
      else if(result.data.success) {
        vacationItem.approved = ! vacationItem.approved;
        if(vacationItem.approved) {
          state.total += vacationItem.total;
        }
        else {
          state.total -= vacationItem.total;
        }
      }
    })
  }
</script>