/**
 * Vue libraries
 */
import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router"
import { createPinia } from 'pinia'
import { useUserStore } from "@/stores/UserStore";

/**
 * Other libraries
 */
import axios from 'axios'
const bootstrap = require('bootstrap');

/**
 * App & routes (main screens)
 */
import App from './App.vue'
import LoginForm from './views/routes/LoginForm.vue'
import UserProfile from './views/routes/UserProfile.vue'
import ResetPasswordQuery from './views/routes/ResetPasswordQuery.vue'
import ResetPassword from './views/routes/ResetPassword.vue'
import TeamVacations from './views/routes/TeamVacations.vue'
import UserVacations from './views/routes/UserVacations.vue'
import NotFound from './views/routes/NotFound.vue'
import AccessDenied from './views/routes/AccessDenied.vue'

/**
 * Axios default config
 */
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

/**
 * Check if user is logged in
 */
axios.get('is_logged_in')
.then((result) => {

  const app = createApp(App)
  app.use(createPinia())

  const userStore = useUserStore();

  if(result.data) {
    userStore.setProfile(result.data);
  }

  const routes = [
    { path: '/', redirect: () => {
      if(userStore.isLoggedIn) {
        return { name: 'team_vacations' }
      }
      else {
        return { name: 'login' }
      }
    }},
    { path: '/login', name:'login', component: LoginForm, meta: { public: true } },
    { path: '/reset-password-query', name:'reset_password_query', component: ResetPasswordQuery, meta: { public: true } },
    { path: '/reset-password/:token', name:'reset_password', component: ResetPassword, meta: { public: true } },
    { path: '/team-vacations', name:'team_vacations', component: TeamVacations },
    { path: '/user/:username/profile', name:'profile', component: UserProfile },
    { path: '/user/:username/:type', name:'user_vacations', component: UserVacations },
    { path: '/access-denied', name:'access_denied', component: AccessDenied },
    { path: '/:pathMatch(.*)*', name: 'not_found', component: NotFound }
  ]

  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  app.use(router)

  router.beforeEach((to) => {
    const userStore = useUserStore();

    if ( ! to.meta.public && ! userStore.isLoggedIn) {
        return { name: 'login' }
    }
  })

  app.mount('#app');
  // enable tooltips
  [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response && error.response.status && error.response.status == 403) {
      router.push({ name: 'access_denied' });
    }
    return Promise.reject(error);
  });
})
