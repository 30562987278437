<template>
  <button @click="logout" data-bs-toggle="tooltip" title="Déconnexion" type="button" class="unstyled">
    <PictoLogOut />
  </button>
</template>

<script setup>
import { useUserStore } from "@/stores/UserStore";
import { useRouter } from 'vue-router'
import PictoLogOut from '../svg/PictoLogOut.vue';
import axios from 'axios'
const userStore = useUserStore();
const router = useRouter();

function logout() {
  axios.get('logout')
  .then(function() {
    userStore.logout();
    router.push({ name: 'login' });
  })
}
</script>