<template>
  <button data-bs-toggle="modal" data-bs-target="#update-vacation" class="btn btn-primary" type="button"><i class="bi bi-plus-circle"></i> Ajouter</button>

  <div class="modal fade" id="update-vacation" tabindex="-1" aria-labelledby="update-vacation-label" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h2 class="modal-title" id="update-vacation-label">Ajouter une transaction</h2>
          <form autocomplete="off" method="post" action="" @submit.prevent="update">
            <div class="form-item">
              <label class="form-label d-block">Type </label>
              <label class="radio-container">RTT
                <input type="radio" v-model="type" name="type" value="RTT">
                <span class="checkmark"></span>
              </label>
              <label class="radio-container">CP
                <input type="radio" v-model="type" name="type" value="CP">
                <span class="checkmark"></span>
              </label>
            </div>

            <div class="form-item">
                <input type="text" v-model="motif" placeholder="Motif" />
            </div>

            <div class="form-item">
                <input type="number" v-model="total" placeholder="Total" step=".1" />
            </div>

            <div class="form-item" v-if="error">
              <div class="alert alert-danger" role="alert">
                {{ error }}
              </div>
            </div>

            <div class="form-item text-center">
              <button type="submit" class="btn btn-primary ladda-button" data-style="expand-right">Valider</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue';
import { useRoute } from 'vue-router'
import axios from 'axios';
import * as Ladda from 'ladda';
const route = useRoute();
const emit = defineEmits(['vacationAdded'])
let type = ref("RTT");
let error = ref("");
let total = ref(null);
let motif = ref("");
let modal;

onMounted(() => {
  const bootstrap = require('bootstrap');
  const modalElement = document.getElementById('update-vacation');
  modal = new bootstrap.Modal(modalElement);
})

function update() {
  let data = new FormData();
  data.append('type', type.value);
  data.append('total', total.value);
  data.append('motif', motif.value);
  data.append('username', route.params.username);
  var l = Ladda.create(document.querySelector('#update-vacation .ladda-button'));
  l.start();
  axios.post('vacations/update', data)
  .then(function(result) {
    l.stop();
    if(result.data.error) {
      error.value = result.data.error;
    }
    else if(result.data.success) {
      emit('vacationAdded')
      modal.hide();
    }
  })
}
</script>