<template>
  <div class="component-wrapper">
    <div class="intro">
      <h1>Oubli du mot de passe</h1>
      <p>Pas de souci, ça arrive même aux meilleurs.</p>
    </div>
    <form method="post" action="" @submit.prevent="reset" v-if="!success" style="max-width: 400px">
      <div class="form-item">
          <input type="email" required v-model="email" placeholder="Votre identifiant" />
      </div>

      <div class="form-item error" v-if="error">
        {{ error }}
      </div>

      <div class="form-submit form-item">
          <button type="submit" class="btn btn-primary ladda-button" data-style="expand-right">Envoyer le mail</button>
      </div>

      <router-link :to="{ name: 'login' }" class="link-default">En fait je m'en rappelle</router-link>

    </form>
    <div class="success" v-else>{{ success }}</div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import * as Ladda from 'ladda';

const email = ref("");
const error = ref("");
const success = ref("");

function reset() {
  var l = Ladda.create(document.querySelector('.ladda-button'));
  l.start();
  axios.post('forgot_password/', {
    'email': email.value
  })
  .then(() => {
    l.stop();
    success.value = 'Si un utilisateur correspondant à cette adresse est trouvé, vous recevrez un mail vous permettant de réinitialiser votre mdp.'
  })
}
</script>