<template>
  <div class="component-wrapper">
    <div class="intro">
      <h1>Nouveau mot de passe</h1>
      <p>Indiquez votre nouveau mot de passe (et ne l'oubliez pas, cette fois).</p>
    </div>
    <form method="post" action="" @submit.prevent="reset" v-if="!success" style="max-width: 400px">
      <div class="form-item">
          <input type="password" v-model="password1" placeholder="Votre nouveau mot de passe" />
      </div>

      <div class="form-item">
          <input type="password" v-model="password2" placeholder="Votre nouveau mot de passe (encore)" />
      </div>

      <div class="form-item error" v-if="error">
        {{ error }}
      </div>

      <div class="form-submit">
          <button type="submit" class="btn btn-primary ladda-button" data-style="expand-right">ET BIM !!!</button>
      </div>

    </form>
    <div v-else>
      <span class="success">{{ success }}</span>
      <router-link :to="{ name: 'login' }" class="btn">Retourner à la connexion</router-link>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import * as Ladda from 'ladda';

import { useRoute, useRouter } from 'vue-router';

const password1 = ref("");
const password2 = ref("");
const error = ref("");
const success = ref("");
const route = useRoute();
const router = useRouter();
const token = route.params.token;

function reset() {
  if(password1.value != password2.value) {
    error.value = "Les mdp doivent correspondre, sinon c'est la porte ouverte à toutes les fenêtres.";
  }
  else {
    var l = Ladda.create(document.querySelector('.ladda-button'));
    l.start();
    axios.post('forgot_password/' + token, {
      'password': password1.value
    })
    .then(() => {
      l.stop();
      router.push({ name: 'login' });
    })
  }
}
</script>

<style scoped>
  input[type="password"] {
    width: 360px;
  }
</style>