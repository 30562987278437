<template>
  <div class="component-wrapper">
    <div class="intro">
      <h1>Profil</h1>
      <p>Salutations {{ state.firstname }} <em>{{ state.nickname }}</em> {{ state.lastname }}, bienvenue à la maison.</p>
    </div>
    <transition name="fade" mode="out-in">
      <form method="post" action="" @submit.prevent="update" v-if="!success">
        <div class="row">
          <div class="col-sm-4">
            <div class="form-item">
                <input type="text" v-model="state.firstname" placeholder="Votre prénom" />
            </div>
            <div class="form-item">
                <input type="text" v-model="state.lastname" placeholder="Votre nom de famille" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-item">
                <input type="email" v-model="state.email" placeholder="Votre email" />
            </div>
            <div class="form-item">
              <input type="text" v-model="state.nickname" placeholder="Petit surnom" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="form-item">
                <input type="password" v-model="password1" placeholder="Changer de mdp" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-item">
                <input type="password" v-model="password2" placeholder="Confirmation" />
            </div>
          </div>
        </div>

        <div class="row" v-if="userStore.isAdmin">
          <div class="col-sm-4">
            <div class="form-item">
                <label>Gain de RTT par mois</label>
                <input type="number" step="0.5" min="0" v-model="state.rttGainByMonth" placeholder="Gain de RTT par mois" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-item">
                <div class="form-check form-switch">
                  <input v-model="state.active" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" style="padding:0">
                  <label class="form-check-label" for="flexSwitchCheckDefault">Actif</label>
                </div>
            </div>
          </div>
        </div>

        <div class="form-submit">
            <button type="submit" class="btn btn-primary ladda-button" data-style="expand-right">Mettre à jour</button>
        </div>
        <div class="error mt-3" v-html="error"></div>
      </form>
      <div v-else>
        <img src="https://media0.giphy.com/media/1ziDTlTl9z9iwVK5QA/giphy.gif?cid=ecf05e47g28jvcioymdtk5f4xmf2tcorehfew2hne8hndz57&rid=giphy.gif&ct=g" class="mb-4" />
        <div class="alert alert-success" style="max-width: 500px;">Changement d'identité réussi !</div>
        <button class="btn btn-primary" @click="success = false;">Retour</button>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from "@/stores/UserStore";
import * as Ladda from 'ladda';

const userStore = useUserStore();
const getUsername = userStore.getUsername;
const router = useRouter();
const axios = require('axios');
const error = ref("");
const success = ref(false);
const password1 = ref("");
const password2 = ref("");
const state = reactive({
  user: {
    firstname: 'Prénom',
    lastname: 'Nom',
    nickname: 'Surnom',
    email: 'test@example.com',
    rttGainByMonth: 0,
    active: true
  }
})
const route = useRoute();

if( ! userStore.isAdmin && getUsername(userStore.email) !== route.params.username) {
  router.push({ name: 'access_denied' });
}
else {
  axios.get('load-profile/' + route.params.username)
  .then((result) => {
    if(result.data.email) {
      state.email = result.data.email;
      state.firstname = result.data.firstname;
      state.lastname = result.data.lastname;
      state.nickname = result.data.nickname;
      state.rttGainByMonth = result.data.rttGainByMonth;
      state.active = result.data.active;
    }
  })
}

function update() {
  // reset errors
  error.value = '';

  // check password
  if(password1.value != password2.value) {
    error.value = 'Les mdp doivent correspondre. Aucun 2ème mojito ne sera autorisé.';
  }

  // check text fields max length
  const maxLength = 50;
  if(state.firstname.length > maxLength) {
    error.value = `Le prénom ne peut dépasser ${maxLength} caractères. Petit·e veinard·e, le changement de prénom est désormais <a target="_blank" href="https://www.service-public.fr/particuliers/vosdroits/F885#:~:text=3Se%20renseigner%20sur%20le,La%20d%C3%A9marche%20est%20gratuite.">gratuit</a> !`;
  }

  if(state.lastname.length > maxLength) {
    error.value = `Le nom de famille ne peut dépasser ${maxLength} caractères. Normalement même si vous vous appelez <a target="_blank" href="https://www.linternaute.com/savoir/magazine/1106504-les-mots-les-plus-longs/1106507-noms-de-famille#:~:text=%2DEn%20France%2C%20plusieurs%20noms%20de,de%20Quinsonas%2DOudinot%20de%20Reggio">Pourroy de L'Auberivière de Quinsonas-Oudinot de Reggio</a>, ça passe.`;
  }

  if(state.nickname.length > maxLength) {
    error.value = `Le surnom ne peut dépasser ${maxLength} caractères. Les meilleures blagues sont les plus courtes.`;
  }

  // if no errors, create data array and send
  if(!error.value) {
    let data = new FormData();
    data.append('email', state.email);
    data.append('firstname', state.firstname);
    data.append('lastname', state.lastname);
    data.append('nickname', state.nickname);
    data.append('password', password1.value);
    data.append('rttGainByMonth', state.rttGainByMonth);
    data.append('active', + state.active);

    var l = Ladda.create(document.querySelector('.ladda-button'));
    l.start();

    axios.post('update-profile/' + route.params.username, data)
    .then((result) => {
      l.stop();
      if(result.data.error) {
        error.value = result.data.error;
      }
      else if(result.data.profile) {
        success.value = true;
      }
    })
  }

}
</script>
